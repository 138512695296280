import React, { Component } from 'react';
import './App.css';
import { MDBIcon, MDBContainer } from 'mdbreact';
import Ad from './Ad';


class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">        
          <p className="word">
            <span>
              [
            </span>
            <strong>dopest</strong>
            <span>
              ]
            </span>
          </p>
          <p className="definition">
            <strong>Adjective.</strong> superlative form of dope: most dope.
          </p>
          <MDBContainer>
            <a href="https://www.twitter.com/aquilastrother" className="tw-ic mr-3">
              <MDBIcon className="twitter-icon" fab icon="twitter" />
            </a>
            <a href="https://www.github.com/astro11" className="git-ic mr-3">
              <MDBIcon className="github-icon" fab icon="github" />
            </a>
            <a href="https://www.aquilacodes.com" className="git-ic mr-3">
            <MDBIcon className="dev-icon" fab icon="fab fa-dev" />
            </a>
          </MDBContainer>
          {/* <Ad /> */}
          <p className="tag">           
            <h1>Aquila Strother - Full-Stack Web Developer</h1>
          </p>          
        </header>
        {/* <Ad />       */}
      </div>
    );
  }
}

export default App;
