import React from 'react';
import './App.css';

export default class Ad extends React.Component {

    componentDidMount() {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }


    render() {
        return (
            <div className="ad">
                <ins className="adsbygoogle"
                    style={{ display: 'block' }}
                    data-ad-client="ca-pub-8328910983930827"
                    data-ad-slot="4043425636"
                    data-ad-format="auto"
                    data-full-width-responsive="true">
                </ins>
            </div>
        );
    }
}


